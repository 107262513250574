@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

.firstNameRoot {
  width: calc(50% - 9px);
}

.lastNameRoot {
  width: calc(50% - 9px);
}

.email {
  margin-top: 16px;
}

.text {
  margin-top: 16px;
}

.password {
  composes: marketplaceModalPasswordMargins from global;
  margin-top: 16px;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
  margin-top: 0;
}

.logInText {
  composes: marketplaceModalHelperLink from global;
  margin-top: 16px;
}

.locationAutocompleteInput {
  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  top: 36px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    top: 40px;
  }
}

.validLocation {
  /* border-bottom-color: var(--colorSuccess); */
}
