@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.password {
  composes: marketplaceModalPasswordMargins from global;
  margin-top: 16px;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
  margin-top: 16px;
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
}

.recoveryLink {
  composes: marketplaceModalHelperLink from global;
  margin-top: 16px;
  text-align: right;
}

.recoveryLinkInfo {
  composes: marketplaceModalHelperText from global;
}
